<template>
	<v-alert
		:class="props.type"
		:type="props.type"
		:icon="alertIcon"
		variant="outlined"
		:closable="props?.closable || false"
	>
		<slot />
	</v-alert>
</template>

<script lang="ts" setup>
interface AlertProps {
	type: "success" | "info" | "warning" | "error";
	closable?: boolean;
}

const props = defineProps<AlertProps>();

const alertIcon = computed(() => {
	switch (props.type) {
		case "success":
			return "md-check";
		case "info":
			return "md-info";
		case "warning":
			return "md-warning";
		case "error":
			return "md-block";
		default:
			return undefined;
	}
});
</script>

<style lang="scss" scoped>
.v-alert {
	@apply p-[14px] min-h-[48px];
	@apply mobile:p-[15px] mobile:h-[50px];
	:deep(.v-alert__content) {
		@apply text-black text-[14px] leading-[20px] text-left;
	}
	:deep(.v-alert__prepend) {
		@apply text-[20px] h-[20px] w-[20px];
		.v-icon {
			font-family: "Material Symbols Outlined";
			@apply not-italic h-[24px] w-[24px] text-[24px] #{!important};
		}
		.md-info::after {
			content: "info";
		}
		.md-check::after {
			content: "check";
		}
		.md-warning::after {
			content: "warning";
		}
		.md-block::after {
			content: "block";
		}
	}

	// Customized background color
	&.success {
		@apply bg-green-50;
	}
	&.info {
		@apply bg-blue-50 border-blue-100;
	}
	&.warning {
		@apply bg-orange-50;
	}
	&.error {
		@apply bg-red-50;
	}
}
</style>
